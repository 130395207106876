import React from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import PageTransition from "../components/transition/page"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }

  animate() {
    this.pageTransition.current.play()
  }

  render() {
    return (
      <Layout theme="light" path={"/en-AU/"} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO title="404: Not found" />
        <div className={"container mx-auto"}>
          <div className={"flex flex-wrap"}>
            <div className={"py-20 w-1/2 mx-auto text-center"}>
              <h1 className="font-agrandir text-coral text-2xl">NOT FOUND</h1>
              <p className={"font-montserrat-regular text-md"}>You just hit a page that doesn&#39;t exist...</p>
              <img src="//media.giphy.com/media/Om3h5DkxuhiRG/source.gif" alt="not found" className={"mx-auto py-4"} />
            </div>
          </div>
        </div>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export default NotFoundPage
